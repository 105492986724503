import React from 'react';
import Logo from '../../login/logoerasto2.png';

const ContainerBox = ({ children, subtitulo, titulo }) => {
  return (
    <div className="container">
      <div className="login">
        <div className="header">
          <div>
            <img src={Logo} className="img-fluid imagem-logo" />
            <h4 className="title-login">{titulo}</h4>
          </div>
        </div>
        <div className="body">
          <h2 className="subtitulo">{subtitulo}</h2>
          {children}
        </div>
      </div>
    </div>
  );
}

export default ContainerBox;