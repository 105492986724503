import axios from 'axios';
const mode = 'prod';//prod ou dev
//url varia de acordo com a constante mode
const url = (mode === 'prod')?"https://areamedica.api.telemedicina.erastogaertner.com.br/"
:'https://areamedica.api.telemedicina.genya.info/';
// const url = "http://localhost:8080/";

export function connection(token) {
    const instance = axios.create({
        baseURL: url+'api',
        timeout: 100000,
        headers: { 'Authorization': 'bearer ' + token }
    });
    return instance;
}
export function connectionWithoutToken() {
    const instance = axios.create({
        baseURL: url,
        timeout: 100000
    });
    return instance;
}
export async function postStatusNrSequencia(token, data){
    const instance = axios.create({
        baseURL: url+'api',
        timeout: 100000,
        headers: { 'Authorization': 'bearer ' + token }
    });
    let erro = false;
    let dataSubmitServer = {nr_sequencia:data.idSubmitReceita,cpf:data.loginCert, pin: data.senhaCert};
    let dados = await instance.post('/status-documentos-consulta',  dataSubmitServer).catch(err=>{
        erro = true;
    }); 
    if(erro){
        return null;
    }
    return dados.data;
}
export async function postEnviarReceituarioAtestado(token,data){
    const instance = axios.create({
        baseURL: url+'api',
        timeout: 100000,
        headers: { 'Authorization': 'bearer ' + token }
    });
    let erro = false;
    let dados = await instance.post('/confirmar-documentos-consulta',data).catch(err=>{
        erro = true;
    }); 
    if(erro){
        return null;
    }

    return dados.data;
}   
export { axios };
