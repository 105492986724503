export function changeStatus(status){
    return {
        type:'TOOGLE_PERMISSAO',
        payload: {
            permissao:status
        }
    }
}

export function changeDados(dados){
    return {
        type:'TOOGLE_DADOSINICIAIS',
        payload:{
            dadosIniciais:dados
        }
    }
}
export function changeLink(link, idUsuario){
    return {
        type:'TOOGLE_LINK',
        payload:{
            linkVideoChamada:{link, idUsuario}
        }
    }
}
export function changeRedSair(status){
    return {
        type:'TOOGLE_REDSAIR',
        payload:{
            status:status
        }
    }
}
export function changeToken(token){
    return {
        type:'TOOGLE_TOKEN',
        payload:{
            token:token
        }
    }
}
export function changeUser(user){
    return {
        type:'TOOGLE_USER',
        payload:{
            user:user
        }
    }
}