import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const ModalComecarConsulta = ({ children, horario, onClickExit, isShowing }) => {

  return (
    <Modal show={isShowing} onHide={onClickExit}>
      <Modal.Header closeButton>
        <Modal.Title>Iniciar consulta - {horario}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClickExit}>
          Fechar
          </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalComecarConsulta;