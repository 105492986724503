import React, { useState } from 'react';
import {Button, Modal} from 'react-bootstrap';
const modal = ({ msg, titulo, id, children, isShowing,onClickExit }) => {

  return (
    <Modal show={isShowing} onHide={onClickExit}>
      <Modal.Header closeButton>
        <Modal.Title>{titulo}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {msg}
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClickExit}>
          Fechar
      </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default modal;