import animationData from './consulta.json';
import ok from './ok.json';
import error from './error.json';
export const defaultOptions = {
  loop: true,
  autoplay: true,
  resizeMode: 'contain',
  autoSize: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};
export const erroAnimation = {
  loop: false,
  autoplay: true,
  resizeMode: 'contain',
  autoSize: true,
  animationData: error,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};
export const erroAnimationLoopingTrue = {
  loop: true,
  autoplay: true,
  resizeMode: 'contain',
  autoSize: true,
  animationData: error,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};
export const okAnimation = {
  loop: false,
  autoplay: true,
  resizeMode: 'contain',
  autoSize: true,
  animationData: ok,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};
