import React, { useState, useEffect } from 'react';
import './login.css';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeStatus, changeUser, changeToken, changeRedSair, changeDados } from './../services/fieldActions';
import { connectionWithoutToken } from './../services/api';
import Footer from '../complements/footer';
import Lottie from 'react-lottie';
import animationData from './loading.json'
import ContainerBox from '../complements/containerBox/index';

const defaultOptions = {
    loop: true,
    autoplay: true,
    resizeMode: 'contain',
    autoSize: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


function Login(props) {
    const [alerta, setAlerta] = useState({ msg: '', type: '' });
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [redirecionar, setRedirecionar] = useState(false);
    const [enviandoServidor, setEnviandoServidor] = useState(false);
    const [carregado, setCarregado] = useState(true);
    async function enviar(event) {
        event.preventDefault();
        setCarregado(false);
        setAlerta({ msg: 'Aguarde, analisando informações', type: 'alert alert-warning' });
        setEnviandoServidor(true);
        var returnError = false;
        let dados = await (await connectionWithoutToken().post('/auth', { usuario: user, senha: password }).catch(
            (err) => {
                setAlerta({
                    msg: 'Ocorreu um erro ao comunicar com o servidor',
                    type: 'alert alert-danger'
                });
                returnError = true;
            }
        ));
        setCarregado(true);
        setEnviandoServidor(false);
        if (returnError) {
            return false;
        }
        dados = dados.data;
        if (!dados) {
            setAlerta({
                msg: 'Ocorreu um erro ao comunicar com o servidor',
                type: 'alert alert-danger'
            });
        }
        if (dados.status === false) {
            setAlerta({ msg: dados.message, type: 'alert alert-danger' });
        } else {
            setAlerta({ msg: 'Ok!', type: 'alert alert-success' });
            props.changeToken(dados.data);
            props.changeUser(user);
            setRedirecionar(true);
            setAlerta({ msg: '', type: '' });
        }

    }
    useEffect(() => {
        if (props.redSair) {
            getRedSair();
        }
    }, [props.redSair]);
    useEffect(() => {
        if (props.redSair) {
            getRedSair();
        }
    }, []);
    function getRedSair() {
        setAlerta({
            msg: 'Acabou sua sessão inicie o login novamente!',
            type: 'alert alert-danger'
        });
        props.changeRedSair(false);

    }
    if (redirecionar) {
        return <Redirect to="/home" />
    } else if (props.token) {
        return <Redirect to="/home" />
    } else {
        return (
            <div className="all">
                {(!carregado) ? <div className="container">
                    <Lottie options={defaultOptions}
                        height={300}
                        width={300} />
                    <div className={alerta.type}>
                        <p className="Fontblack alinharTexto">{alerta.msg}</p>
                    </div></div> : ''}

                {(carregado) ?
                    <div className="container">
                        <ContainerBox titulo="TELEMEDICINA" sbutitulo="Login">
                            <div className={alerta.type}>
                                <p className="Fontblack alinharTexto">{alerta.msg}</p>
                            </div>
                            <form onSubmit={enviar}>
                                <label htmlFor="user">Usuário</label>
                                <div className="form-group form-inline">

                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <span className="material-icons">
                                                account_circle
                                            </span>
                                        </span>
                                    </div>
                                    <input className="form-control" type="text" id="user"
                                        value={user} onChange={e => setUser(e.target.value)} required />
                                </div>
                                <label htmlFor="password">Senha</label>
                                <div className="form-group form-inline">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <span className="material-icons">
                                                https
                                            </span>
                                        </span>
                                    </div>
                                    <input className="form-control"
                                        type="password" id="password"
                                        value={password} onChange={e => setPassword(e.target.value)} required />
                                </div>
                                <div className="espacamento row justify-content-center">
                                    <button className="btn btn-agendamento medium" disabled={enviandoServidor}>Acessar telemedicina</button>
                                </div>
                            </form>
                        </ContainerBox>
                    </div>


                    : ''}
                <Footer />
            </div>
        );
    }

}
function mapStateToProps(state) {
    return {
        permissao: state.aplicacaoAgendamento.permissao,
        dadosIniciais: state.aplicacaoAgendamento.dadosIniciais,
        redSair: state.aplicacaoAgendamento.redSair,
        token: state.aplicacaoAgendamento.token,
    }

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ changeStatus, changeUser, changeToken, changeDados, changeRedSair }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);