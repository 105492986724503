import React from 'react';
import { Link } from 'react-router-dom';
import './menu.css';
import logoErasto from './logo.png'
export default function Menu({ nomePessoa }) {
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-agendamento bg-agendamento">
                <div className="container">
                    <Link className="navbar-brand" to="/home">
                        <img src={logoErasto} className="img-fluid" alt="Logo erasto gaertner" />
                    </Link>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle itemNome"
                            id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {nomePessoa}
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <Link className="dropdown-item " to="/home">Home</Link>
                            <Link className="dropdown-item " to="/sair">Sair</Link>
                        </div>
                    </li>
                </div>
            </nav>

        </>
    );
}