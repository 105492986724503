import fieldReducer from '../services/fieldReducer';
import { combineReducers, createStore } from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';


const reducers = combineReducers({
    aplicacaoAgendamento: fieldReducer
});

const persistConfig = {
    key:'root',
    storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(persistedReducer);
const persistor = persistStore(store);

export  {store, persistor};