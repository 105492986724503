import React from 'react'
import './footerStyle.css';
import LogoGenya from './genya-logo.png';
export default function Footer() {
  return (
    <>
      <div className="bloco-logo">
        <div className="logoBox">
          <img src={LogoGenya} className="img-fluid" alt="Logo genya" />
          <p className="espacamentoTop">Copyright <b>©{new Date().getFullYear()}</b> - Telemedicina - HEG</p>
        </div>
      </div>
    </>
  );
}