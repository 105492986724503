//importações essenciais react
import React from 'react';
import ReactDOM from 'react-dom';
import {Redirect} from 'react-router-dom';
import './index.css';
//import * as serviceWorker from './serviceWorker';


//componentes para importar
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Home from './components/home/index';
import Login from './components/login/login';

//redux imports 
import { store, persistor } from './components/services/combinersRedux';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import PrivateRouter from './components/PrivateRouter';
import Sair from './components/sair';



ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <PrivateRouter path='/home' component={Home} />
          <Route path="/sair" component={Sair} />
          <Route path="/login" component={Login} />
          <Route path="/"  exact={true} component={Login} />
        </PersistGate>
      </Provider>   
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);
