const initial_state = { permissao: false, dadosIniciais: {}, token: null,
    redSair: false, linkVideoChamada: {link: '', idSelecionado:0},
 user: '' };
export default function (state = initial_state, action) {
    switch (action.type) {
        case 'TOOGLE_PERMISSAO':
            return { ...state, permissao: action.payload.permissao };
        case 'TOOGLE_DADOSINICIAIS':
            return { ...state, dadosIniciais: action.payload.dadosIniciais };
        case 'TOOGLE_USER':
            return { ...state, user: action.payload.user };
        case 'TOOGLE_TOKEN':
            return { ...state, token: action.payload.token };
        case 'TOOGLE_REDSAIR':
            return {...state, redSair:action.payload.status};
        case 'TOOGLE_LINK':
            return {...state, linkVideoChamada: action.payload.linkVideoChamada}    
        default:
            return state;
    }

}