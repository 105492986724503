import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changeStatus, changeToken } from './../services/fieldActions';
import { Redirect } from 'react-router-dom';
function Sair(props) {
    useEffect(() => {
        props.changeToken('');
        props.changeStatus(false);
    }, []);
    return (
        <Redirect to="/login" />
    );
}

function mapStateToProps(state) {
    return {
        dadosIniciais: state.aplicacaoAgendamento.dadosIniciais,
        token: state.aplicacaoAgendamento.token,
        email: state.aplicacaoAgendamento.email
    }

}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ changeStatus, changeToken }, dispatch);
}



export default connect(mapStateToProps, mapDispatchToProps)(Sair);
