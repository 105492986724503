import React, { useState, useEffect, useRef } from 'react';
import './home.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeStatus, changeUser, changeToken, changeRedSair, changeDados, changeLink } from './../services/fieldActions';
import { connection, postEnviarReceituarioAtestado, postStatusNrSequencia } from './../services/api';
import { Redirect } from 'react-router';
import Footer from '../complements/footer/';
import Menu from '../complements/menu';
import Lottie from 'react-lottie';
import MaskedInput from 'react-text-mask';
import useInterval from './../complements/useInterval';
import ModalPadrao from '../complements/modal';
import ModalComecarConsulta from '../complements/modal/ModalComecarConsulta';
import Table from '../complements/table';
import { data } from '../complements/DataAtual';
import { defaultOptions, erroAnimation, okAnimation, erroAnimationLoopingTrue } from './animacoes';

var inCarregando = false;
var inSubmitServerMessage = false;

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)
function Home(props) {
  //declaração de states
  const [dados, setDados] = useState([]);
  const [todosDados, setTodosDados] = useState([]);
  const [erro, setErro] = useState(false);
  const [carregado, setCarregado] = useState(false);
  const [email, setEmail] = useState('');
  const [alerta, setAlerta] = useState({ type: '', msg: '' });
  const [telefone, setTelefone] = useState('');
  const [telefoneResidencial, setTelefoneResidencial] = useState('');
  const [link, setLink] = useState('');
  const [idSelecionado, setIdSelecionado] = useState(0);
  const [idSubmitReceita, setIdSubmitReceita] = useState(0);
  const [buttonConfirm, setButtonConfirm] = useState(false);
  const [emails, setEmailsTag] = useState([{ id: 0, email: '' }]);
  const [telefones, setTelefonesTag] = useState([{ id: 0, telefone: '' }]);
  const [horario, setHorario] = useState('');
  const [filtro, setFiltro] = useState('Todos');
  const [executadoAttDados, setExecutadoAttDados] = useState(true);
  const [dadosModal, setDadosModal] = useState({ titulo: '', msg: '' });
  const [statusDocumentosConsultas, setStatusDocumentosConsultas] = useState({ atestado: false, receituario: false });
  const [carregandoStatusConsulta, setCarregandoStatusConsulta] = useState(false);
  const [linkToClose, setLinkToClose] = useState('');
  const [consultasAbertas, setConsultasAbertas] = useState([]);
  const [permissaoToContinueChamada, setPermissaoToContinueChamada] = useState(false);
  const [showModal, setShowModal] = useState({
    modalConsulta: false,
    modalCancelarConsulta: false
  });
  const [showModalConfirmarConsulta, setShowModalConfirmarConsulta] = useState(false);
  const [loginCert, setLogin] = useState('');
  const [senhaCert, setSenha] = useState('');
  const [formularioCert, setFormularioCert] = useState(false);

  const blocoAlerta = useRef(null);
  const irBlocoAlerta = () => scrollToRef(blocoAlerta);


  /*
      A cada 16 segundos busca do servidor, para atualizar informações na página
  */
  useInterval(getStatusConsulta, executadoAttDados ? 16000 : null);


  useEffect(() => {
    return () => {
      setExecutadoAttDados(false);
    };
  }, []);

  async function getDados() {
    setAlerta({ type: '', msg: '' });
    let dado = await connection(props.token).post('/consultas').catch(err => {
      setCarregado(true);
      props.changeRedSair(true);
      setErro(true);
      closeModal('comecarConsulta');
      closeModal('cancelarConsulta');
    });

    if (!dado) {
      return;
    }
    setCarregado(true);
    dado = dado.data;

    if (dado.status === false) {
      if (dado.message === 'Signature verification failed') {
        closeModal('comecarConsulta');
        setErro(true);
        props.changeRedSair(true);

      }
      setAlerta({ type: 'alert alert-danger', msg: dado.message })
    } else {
      var consultas = [];
      for (let i = 0; i < dado.data.length; i++) {
        dado.data[i].id = dado.data[i].SEQUENCIA;
        dado.data[i].link = (dado.data[i].LINK) ? dado.data[i].LINK : '';
        if (dado.data[i].LINK) {
          consultas.push({ link: dado.data[i].LINK });

        }
      }
      setConsultasAbertas(consultas);
      setDados(dado.data);

      setTodosDados(dado.data);
    }

  }
  useEffect(() => {
    getDados();
  }, []);
  useEffect(() => {
    atualizarDados();
  }, [dados]);
  useEffect(() => {
    setEmailsTag([]);
    if (email) {
      let emails = email.split(';');
      let arrayTag = [];
      for (let i = 0; i < emails.length; i++)
        arrayTag[i] = { id: i, email: emails[i] }
      setEmailsTag(arrayTag)
    }


  }, [email]);
  useEffect(() => {
    setTelefonesTag([]);
    if (telefone) {
      let telefones = telefone.split(';');
      let arrayTag = [];
      for (let i = 0; i < telefones.length; i++)
        arrayTag[i] = { id: i, telefone: telefones[i] }
      setTelefonesTag(arrayTag)
    }


  }, [telefone]);

  useEffect(() => {
    //lógica para mudar os dados com base no filtro
    if (filtro == 'consultado' || filtro == 'naoconsultado') {
      setDados([]);
      let arrayDados = [];
      for (let i = 0; i < todosDados.length; i++) {
        if (filtro === 'consultado' && todosDados[i].CONSULTADO === true) {
          arrayDados.push(todosDados[i]);
        } else if (filtro === "naoconsultado" && todosDados[i].CONSULTADO === false) {
          arrayDados.push(todosDados[i]);
        }
      }
      setDados(arrayDados);
    } else {
      setDados([]);
      let arrayDados = [];
      for (let i = 0; i < todosDados.length; i++) {
        if (todosDados[i].CONVENIO
          && todosDados[i].CONVENIO.includes(filtro)) {
          arrayDados.push(todosDados[i]);
        } else if (filtro == "Todos") {
          arrayDados.push(todosDados[i]);
        }
      }
      setDados(arrayDados);
    }
  }, [filtro]);
  function atualizarDados() {
    /*
        Atualizar dados com dados vindo do redux, precisa ser executado apenas uma vez
    */
    if (props.link &&
      (props.link.link !== undefined && props.link.link !== null) && !executadoAttDados) {
      setExecutadoAttDados(true);
      let dataProps = props.link.link;
      if (!dataProps) {
        return;
      }
      setLink(dataProps.link);
      setIdSelecionado(dataProps.idSelecionado);
    }
  }
  function posicionarIconeWithProps(linkInserido, id) {
    /*
      Colocar Consulta na tabela
      */
    let arrayDados = dados;
    for (let i = 0; i < arrayDados.length; i++) {
      if (arrayDados[i].id == id) {
        arrayDados[i].link = linkInserido;
      }
    }
    setDados(arrayDados);
  }
  function removeIcon(id, type = 'withIconEquals') {
    /*
        Remover icones de Video e cancelar consulta
    */
    if (type == 'withIconEquals') {
      let arrayDados = dados;
      setDados([]);
      for (let i = 0; i < arrayDados.length; i++) {
        if (arrayDados[i].id == id) {
          arrayDados[i].link = '';
        }
      }
      setDados(arrayDados);
    } else {
      let arrayDados = dados;
      setDados([]);
      for (let i = 0; i < arrayDados.length; i++) {
        if (arrayDados[i].id != id) {
          arrayDados[i].link = '';
        }
      }
      setDados(arrayDados);
    }
  }
  function definirInfos(email, telefone, residencial, id, hor) {
    setHorario(hor);
    verifyStatusConsulta();
    if (!consultasAbertas.length <= 0) { return false; }
    setAlerta({msg:'', type:''});
    /*
    define varias variaveis para melhor demonstração do que está sendof eita
     */
    setEmail(email);
    setTelefone(telefone);
    setTelefoneResidencial(residencial);
    setIdSelecionado(id);
    setHorario(hor);

  }
  async function comecarConsultaEnviar(event = null) {
    /*
      Enviar mensagem ao servidor  para nova consulta
    */
    if (event !== null)
      event.preventDefault();
    setButtonConfirm(true);
    inSubmitServerMessage = true;
    setAlerta({ type: '', msg: '' });
    if (!email && !telefone) {
      setAlerta({
        type: 'alert alert-danger',
        msg: 'Pelo menos um dos dois campos deve ser preenchido!'
      });
      setButtonConfirm(false);
      inSubmitServerMessage = false;
      return;
    }
    let dado = await connection(props.token).post('/comecarconsulta', {
      email,
      telefone: telefone,
      id_agenda: idSelecionado
    }).catch(err => {
      console.log(err)
      setErro(true);
      props.changeRedSair(true);
      closeModal('comecarConsulta');
      closeModal('cancelarConsulta');
      return;
    });
    if (!dado) {
      setErro(true);
      props.changeRedSair(true);
      closeModal('comecarConsulta');
      closeModal('cancelarConsulta');
      return;
    }
    console.log(dado)

    dado = dado.data;

    if (dado.status === true) {
      if (link) {
        cancelConsult(link, 0, false);
        removeIcon(idSelecionado, 'withIconNotEquals');
      }
      let emailEnviado = (dado.enviadoEmail) ? "Email foi enviado com sucesso" : "Email não foi enviado";
      let telefoneEnviado = (dado.enviadoTelefone) ? ", SMS foi enviado com sucesso" : ", SMS não foi enviado";
      let whatsAppEnviado = (dado.enviadoWhatsApp) ? ', WhatsApp foi enviado com sucesso' : ', WhatsApp não foi enviado';
      setAlerta({
        type: 'alert alert-success',
        msg: "Foi enviado o link para consulta: " + emailEnviado + "" + telefoneEnviado + whatsAppEnviado
      });
      props.changeLink({ link: dado.link, idSelecionado });
      setLink(dado.link);
      posicionarIconeWithProps(dado.link, idSelecionado);
      putNewAlert(idSelecionado);

      const consultasAbert = Array.from(
        consultasAbertas);
      consultasAbert.push({ link: dado.link });
      setConsultasAbertas(consultasAbert);
      closeModal('comecarConsulta');
      redirecionarVideo(dado.link);
    } else {
      closeModal('comecarConsulta');
      setAlerta({ type: 'alert alert-danger', msg: dado.message });
      irBlocoAlerta();

    }
    setButtonConfirm(false);
    inSubmitServerMessage = false;
  }
  function putNewAlert(id) {
    /*
      colocar nova mensagem de consulta nas tabelas
    */
    let dataAtual = new Date();
    let arrayDados = dados;
    setDados([]);
    for (let i = 0; i < arrayDados.length; i++) {
      if (arrayDados[i].id == id) {
        arrayDados[i].CONSULTADO = true;
        arrayDados[i].DATA_CONSULTA = data() + ' - ' + dataAtual.getHours() + ':' + dataAtual.getMinutes();
      }
    }
    setDados(arrayDados);
  }
  async function getStatusConsulta() {
    /*
      os dados precisam ter iniciado com os valores e não estar enviando mensagem ao servidor e nem 
      já estar executando uma requisição ao consultas-status
    */

    if (dados.length > 0 && !inCarregando && !inSubmitServerMessage) {
      inCarregando = true;
      let cont = true;
      let data = await connection(props.token).get('/consultas-status').catch(err => {
        cont = false;
        setErro(true);
        props.changeRedSair(true);
        closeModal('comecarConsulta');
        closeModal('cancelarConsulta');
      });
      if (!cont) {
        setErro(true);
        props.changeRedSair(true);
        closeModal('comecarConsulta');
        closeModal('cancelarConsulta');
        return;
      }
      let dadosConsultaData = data.data;
      let arrDados = [];
      let dadosOriginais = dados;
      let atualizarDados = false;
      for (let i = 0; i < dadosOriginais.length; i++) {
        for (let x = 0; x < dadosConsultaData.length; x++) {
          //se vier status diferente ele atualiza os dados  
          if (dadosOriginais[i].CONSULTADO == false && (dadosOriginais[i].id == dadosConsultaData[x].id_agenda)) {
            dadosOriginais[i].CONSULTADO = true;
            dadosOriginais[i].DATA_CONSULTA = dadosConsultaData[x].data_consulta + ' - ' + dadosConsultaData[x].hora_consulta;
            atualizarDados = true;
          }
        }
        arrDados.push(dadosOriginais[i]);
      }

      if (atualizarDados) {
        setDados(arrDados);
      }
      inCarregando = false;
    }
  }
  function removeStatusConsultaArray(link) {
    let dt = [];
    for (let i = 0; i < consultasAbertas.length; i++) {
      if (link !== consultasAbertas[i].link) {
        dt.push(consultasAbertas[i]);
      }
    }
    setConsultasAbertas(dt);

  }
  function verifyStatusConsulta() {
    setPermissaoToContinueChamada(false);
    if (consultasAbertas.length <= 0) {
      setPermissaoToContinueChamada(true);
    }
  }
  function handleEmail(email) {
    setEmail(email);
  }
  function startNrSequencia(link, nr_sequencia) {
    setDadosModal({ titulo: "Aguarde verificando status!", msg: '' });
    setIdSubmitReceita(nr_sequencia);
    setLinkToClose(link);
    setShowModal({ modalCancelarConsulta: true, modalConsulta: false })
    setFormularioCert(false);
    setCarregandoStatusConsulta(false);
    getStatusNrSequencia(nr_sequencia);
  }
  //pega o status, se existe o número de sequencia
  async function getStatusNrSequencia(nr_sequencia) {
    setFormularioCert(false);
    setAlerta({ type: '', msg: '' });

    setDadosModal({ titulo: "", msg: "" });
    setCarregandoStatusConsulta(false);
    setStatusDocumentosConsultas({ receituario: false, atestado: false });
    let msg = "Verifique se as informações encontrada estão corretas.";
    let dados = await postStatusNrSequencia(props.token, { idSubmitReceita: nr_sequencia, loginCert, senhaCert });
    if (!dados) {
      setDadosModal({ titulo: "Erro!", msg: 'Ocorreu um erro ao comuicar com o servidor!' });
    }
    if (dados.atestado === undefined) {
      setDadosModal({ titulo: "Erro!", msg: 'Ocorreu um erro ao comuicar com o servidor!' });
      return false;
    }
    setCarregandoStatusConsulta(true);
    setStatusDocumentosConsultas({ receituario: dados.receita, atestado: dados.atestado });
    if (dados.receita === true || dados.atestado === true) {
      if (dados.receita === true && dados.atestado === true)
        setDadosModal({ titulo: "Atenção!", msg: msg });
      else
        setDadosModal({ titulo: "Atenção!", msg: msg });
    } else {
      setDadosModal({ titulo: "Atenção!", msg: msg });
    }
  }
  //envia mensagem ao servidor para enviar a receita e o atestado
  async function submitReceituarioAtestado() {
    setCarregandoStatusConsulta(false);
    if (statusDocumentosConsultas.receituario === false &&
      statusDocumentosConsultas.atestado === false
    ) {
      lastSubmit();
    } else {
      setDadosModal({ titulo: "Login Certisign", msg: '' });
      setFormularioCert(true);
    }

  }
  async function lastSubmit(e = null) {
    if (e !== null) {
      e.preventDefault();
      setFormularioCert(false);
    }
    setDadosModal({ msg: '', titulo: 'Em andamento!' });
    setAlerta({ type: 'alert alert-warning', msg: 'Isso pode demorar, aguarde...' });
    let dados = await postEnviarReceituarioAtestado(props.token, { nr_sequencia: idSubmitReceita, cpf: loginCert, pin: senhaCert });

    setCarregandoStatusConsulta(true);
    if (!dados) {
      setErro(true);
      props.changeRedSair(true);
      closeModal('comecarConsulta');
      closeModal('cancelarConsulta');
      setAlerta({ type: 'alert alert-danger', msg: 'Não foi possivel enviar' });
      return;
    }
    if(!dados?.login){
      setDadosModal({ titulo: "Login Certisign", msg: '' });
      setAlerta({ msg: "Credencial Inválida!", 
      type: 'alert alert-danger' });
      setFormularioCert(true);
      setCarregandoStatusConsulta(false);
      return;
    }
    if (!dados?.status) {
      closeModal('cancelarConsulta');
      setAlerta({ msg: dados.msg, type: 'alert alert-danger' });
      return;
    }
    if (dados?.receita === undefined) {
      setDadosModal({ titulo: "Erro!", msg: 'Ocorreu um erro ao comuicar com o servidor!' });
      closeModal('cancelarConsulta');
      return false;
    }
    excluirChamada(linkToClose, idSubmitReceita, false);
    removeStatusConsultaArray(linkToClose);
    closeModal('cancelarConsulta');
    let receituarioEnviado = (dados.receita) ? 'Receituario enviado com sucesso' : 'Receituario não foi enviado';
    let atestadoEnviado = (dados.atestado) ? ', Atestado enviado com sucesso':  ', Atestado não foi enviado';
    setAlerta({ type: 'alert alert-success', msg: 'Sucesso! Consulta fechada! '+receituarioEnviado+ atestadoEnviado });
    irBlocoAlerta();
  }

  /*
  para cancelar a consulta e remover o icone de video
  se passar o idSelecionado será removido com o icone escolhido
  se passado o mostrar mensagem como false(padrão true) não será mostrado mensagens
  */
  async function cancelConsult(linkSelecionado, idSelecionado = 0, mostrarMensagem = true) {
    setAlerta({ type: '', msg: '' });

    let dado = await connection(props.token).post('/fecharconsulta', {
      link: linkSelecionado
    }).catch(err => {

      setErro(true);
      props.changeRedSair(true);

      return false;
    });
    if (!dado) {

      setErro(true);
      props.changeRedSair(true);

      return false;
    }
    setButtonConfirm(false);
    dado = dado.data;
    if (dado.status === true) {
      if (mostrarMensagem === true)
        setAlerta({ type: 'alert alert-success', msg: dado.message });

      if (idSelecionado != 0)
        posicionarIconeWithProps('', idSelecionado);

      return true;

    } else {
      if (mostrarMensagem === true)
        setAlerta({ type: 'alert alert-danger', msg: dado.message });


      return false;

    }
  }
  //limpa as mensagens do servidor
  function cleanMessage() {
    setAlerta({ type: '', msg: '' });
    setEmail('');
    setTelefone('');
    setTelefoneResidencial('');
  }
  function closeModal(type = 'comecarConsulta') {
    switch (type) {
      case 'comecarConsulta':
        setShowModal({ ...showModal, modalConsulta: false });
        break;
      case 'cancelarConsulta':
        setShowModal({ ...showModal, modalCancelarConsulta: false });
        break;
    }
  }
  //redireciona para a chamda de video em outra aba com o parametro _blank
  function redirecionarVideo(linkParam = link) {
    window.open(linkParam, '_blank');
  }
  //exclui do servidor a chamada
  function excluirChamada(link, idSelecionado, mostrarAlerta = true) {
    if (cancelConsult(link, 0, mostrarAlerta)) {
      props.changeLink({ link: '', idSelecionado: 0 });
      setLink('');
      setIdSelecionado(0);
      removeIcon(idSelecionado);
    }
  }
  if (erro) {
    return <Redirect to="/sair" />
  }
  const handleKeyDown = (event, type) => {
    if (type == 'email') {
      if (event.key === 'Enter') {
        setEmail(email + ";");
      }
    } else if (type == 'telefone') {
      if (event.key === 'Enter') {
        setTelefone(telefone + ";");
      }
    } else {
      if (event.key === 'Enter') {
        comecarConsultaEnviar();
      }
    }
  }


  return (
    <>
      <Menu nomePessoa={props.user} />
      <ModalPadrao id={'modal' + idSelecionado} isShowing={showModal.modalCancelarConsulta} titulo={dadosModal.titulo} msg={dadosModal.msg}
        onClickExit={() => { closeModal('cancelarConsulta') }}  >
        <div className={alerta.type}>{alerta.msg}</div>
        {formularioCert === true ? <>
          <h4>Informe sua credencial</h4>

          <hr />
          <div className="container">
            <form onSubmit={e => lastSubmit(e)}>
              <div className="form-group">
                <label htmlFor="cpf">CPF</label>
                <MaskedInput
                  mask={[/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]}
                  className="form-control"
                  onChange={e => setLogin(e.target.value)}
                  value={loginCert}
                  id="cpf"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="senha" >Senha</label>
                <input onChange={(e) => setSenha(e.target.value)} value={senhaCert} id="senha" type="password" className="form-control" required />
              </div>
              <div className="row justify-content-center">
                <button className="btn btn-primary">Enviar credenciais</button>
              </div>
            </form>
          </div>
        </> : ''}

        {carregandoStatusConsulta === true ?
          <>
            <hr />
            {statusDocumentosConsultas.atestado === true && formularioCert == false ? <div className="font-black">
              <p className="alinhar-texto-centro">Atestado</p>
              <Lottie options={okAnimation}
                height={100}
                width={100} /> </div> : <div className="font-black">
                <p className="alinhar-texto-centro">Atestado</p>
                <Lottie options={erroAnimation}
                  height={65}
                  width={65} /> </div>}
            <hr />
            {statusDocumentosConsultas.receituario === true && formularioCert == false ? <div className="font-black">
              <p className="alinhar-texto-centro">Receituario</p>
              <Lottie options={okAnimation}
                height={100}
                width={100} /> </div> : <div className="font-black">
                <p className="alinhar-texto-centro">Receituario</p>
                <Lottie options={erroAnimation}
                  height={65}
                  width={65} /> </div>}
            <hr />
            <button className="btn btn-primary large" title="Fechar a consulta e enviar atestado/receituario"
              onClick={submitReceituarioAtestado}>Confirmar e fechar consulta</button>
          </>
          :
          ''

        }
        {
          // formularioCert 
          carregandoStatusConsulta == false && formularioCert == false ?
            <div className="container">
              <Lottie options={defaultOptions}
                height={300}
                width={300} /> </div> : ''
        }

      </ModalPadrao>
      <ModalComecarConsulta horario={horario} isShowing={showModal.modalConsulta} onClickExit={() => { cleanMessage(); closeModal('comecarConsulta') }} >
        {permissaoToContinueChamada === true ?
          <>
            <div className={alerta.type}>{alerta.msg}{(link && alerta.msg) ?
              <>
                <br />
                <a className="linkVideo" onClick={() => { redirecionarVideo() }}>
                  Clique aqui para entrar na video chamada!
            </a>
              </> : ''}</div>
            <div className="modal-body">
              <div className="container">
                <div className="form-group">
                  <label htmlFor="telefone">Telefone</label>
                  {telefones.map(e => (
                    <p key={e.telefone} className="tag-email">{e.telefone}</p>
                  ))}
                  <input
                    className="form-control"
                    onChange={e => setTelefone(e.target.value)}
                    value={telefone || ''}
                    id="telefone"
                    onKeyDown={e => { handleKeyDown(e, 'telefone') }}
                  />
                </div>
                <div className="form-group">
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    {emails.map(e => (
                      <p key={e.email} className="tag-email">{e.email}</p>
                    ))}
                    <input className="form-control" id="email" value={email || ''}
                      onChange={e => { handleEmail(e.target.value) }}
                      onKeyDown={e => { handleKeyDown(e, 'email') }}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group">
                    <label htmlFor="telefoneResidencial">Telefone Residencial</label>
                    <input className="form-control" id="telefoneResidencial" value={telefoneResidencial || ''}
                      onChange={e => { setTelefoneResidencial(e.target.value) }}
                      onKeyDown={e => { handleKeyDown(e, 'none') }}
                    />
                  </div>
                </div>


                <div className="row justify-content-center">
                  <button type="submit" className="btn btn-primary"
                    disabled={buttonConfirm}
                    onClick={comecarConsultaEnviar}
                  >Enviar Link para consulta</button>
                </div>
              </div>
            </div></> : <div className="container">
            <p className="font-black">Erro, por favor feche sua última consulta! </p>
            <Lottie options={erroAnimationLoopingTrue}
              height={200}
              width={200} />
          </div>}

      </ModalComecarConsulta>


      <div className="container espacamentoTop">
        <h1 className="title">Consultas</h1>
        <hr />

        <div className="row justify-content-between espacamento">
          <p className="txtBlack">Dia de Hoje:{data()}</p>

          {(carregado) ? <div className="form-group">
            <label htmlFor="filtro">Filtro por agenda</label>
            <select className="form-control" id="filtro" onChange={e => setFiltro(e.target.value)}>
              <option value="Todos">Todos</option>
              <option value="SUS">SUS</option>
              <option value="Particular">Particular</option>
              <option value="Sesmt">Sesmt</option>
              <option value="naoconsultado">Não consultado</option>
              <option value="consultado">Consultado</option>
            </select>

          </div> : ''}
        </div>

        {(!carregado) ? <div className="container">
          <Lottie options={defaultOptions}
            height={300}
            width={300} /> </div> : ''}
        {(carregado) ?
          <>
            <div ref={blocoAlerta} className={alerta.type}>{alerta.msg}</div>
            <Table>
              {dados.map(element => (
                <tr id={element.id} key={element.id}>
                  <td>{element.PACIENTE}</td>
                  <td><b>{element.HORA}</b></td>
                  <td>{element.DURACAO}</td>
                  <td>{element.STATUS}</td>
                  <td>{element.CONVENIO}</td>
                  <td>
                    {(element.CONSULTADO == false) ? <div className="alert alert-danger" id={element.id + 'divConsulta'}>Não consultado</div> :
                      <div className="alert alert-success" id={element.id + 'divConsulta'}>Consulta Realizada - <b>{element.DATA_CONSULTA}</b></div>
                    }
                  </td>
                  <td>
                    <button className="btn btn-agendamento"
                      title="Começar Chamada com usúario"
                      onClick={() => {
                        definirInfos(element.EMAIL, element.TELEFONE, element.TELEFONE_RESIDENCIAL, element.id, element.HORA);
                        setShowModal({ ...showModal, modalConsulta: true });
                      }
                      }
                    >Começar Chamada</button>&nbsp;
                    {(element.link) ?
                      <>
                        <button onClick={() => { redirecionarVideo(element.link) }}
                          title="Ir para a chamada de video"
                          type="button" className="btn btn-success">
                          <span className="material-icons">
                            videocam
                          </span>
                        </button>
                      &nbsp;
                        <button onClick={() => {
                          startNrSequencia(element.link, element.id);
                          setShowModal({ ...showModal, modalCancelarConsulta: true });
                        }}
                          title="Fechar consulta"
                          className="btn btn-primary"

                          type="button" className="btn btn-danger">
                          X
                        </button>
                      </>
                      : ''}

                  </td>
                </tr>
              ))}
            </Table>
          </> : ''}
      </div>

      <Footer />
    </>
  );
}
function mapStateToProps(state) {
  return {
    permissao: state.aplicacaoAgendamento.permissao,
    dadosIniciais: state.aplicacaoAgendamento.dadosIniciais,
    redSair: state.aplicacaoAgendamento.redSair,
    token: state.aplicacaoAgendamento.token,
    user: state.aplicacaoAgendamento.user,
    link: state.aplicacaoAgendamento.linkVideoChamada
  }

}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeStatus, changeUser,
    changeToken, changeDados, changeRedSair, changeLink
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Home);
