import React from 'react';


const table = ({ children }) => {
  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Paciente</th>
            <th scope="col">Horario</th>
            <th scope="col">Duração</th>
            <th scope="col">Status</th>
            <th scope="col">Convenio</th>
            <th scope="col">Consultado</th>
            <th scope="col">Ações</th>
          </tr>
        </thead>
        <tbody>
          {children}
        </tbody>
      </table>
    </div>
  );
}

export default table;